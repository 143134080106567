async function runApp() {
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    Vue.config.productionTip = false;

    const { router, vuetify } = await import(/* webpackChunkName: "plugins" */ '@/plugins');
    const { default: AppComponent } = await import(/* webpackChunkName: "app.vue" */ '@/App.vue');
    new Vue({
        router: await router(),
        vuetify: await vuetify(),
        render: h => h(AppComponent)
    }).$mount('#app');
}

runApp();
